import React, { useEffect, useState } from 'react';
import s from './style.scss';
import FacetFilter from '../FacetFilter';
import loadash, { isEmpty } from 'lodash';
import BreadCrumb from '../BreadCrumb';
import ParslogicFitmentSelectorWrapper from '../PartslogicFitmentSelectorWrapper';
import PartslogicProductListWrapper from '../PartslogicProductListWrapper';
import {
  setNumberOfFitmentsTosave,
  updateFacetQueryParams,
} from '../../utils/fitmentUtils';
import {
  FACET_PREFIX,
  getInitialFitment,
  getProductQuery,
  getSearchParams,
  isEqual,
  parseVehicleQualifierQuery,
  SearchParams,
  updateUrl,
} from '../PartslogicSearchPage/utils';
import { Product } from '../../models/search';
import { SortOption } from '../ProductList/models';
import { FITMENT_SELECTOR_STORAGE_KEY } from '../../utils/constants';
import { Orientation } from '../../models/generic';

const getQueryParam = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('q');
};
const clearSearchParam = () => {
  const params = new URLSearchParams(window.location.search);
  params.delete('q');
  window.history.pushState({}, '', location.pathname + `?${params.toString()}`);
};
const getFacetKeysFromUrl = (queryString: URLSearchParams) => {
  const fitmentQuery = [];
  for (const key of Array.from(queryString.keys())) {
    if (key.includes(FACET_PREFIX)) {
      fitmentQuery.push(key);
    }
  }
  return fitmentQuery;
};
const clearVqParams = () => {
  const params = new URLSearchParams(window.location.search);
  params.forEach((value, key) => {
    if (key.includes('vq[')) {
      params.delete(key);
    }
  });
  window.history.pushState({}, '', location.pathname + `?${params.toString()}`);
};
type InitialFacets = {
  [name: string]: string[];
};
type Props = {
  /** Id of the fitment group your site uses for fitment.*/
  groupId: number;
  /** Will determine the search used for the results */
  searchString?: string;
  /** {"facet": ["facetValue"]} object, will condition search with an initial facet selection. Value is an array* */
  initialFacets?: InitialFacets;
  /** If set, will display above the fitment selector.*/
  fitmentSelectorTitle?: string;
  /** Label of the search button under the fitment selector. Has a default value.*/
  fitmentSelectorSearchButton?: string;
  /** Label of the clear button under the fitment selector. Has a default value.*/
  fitmentSelectorClearButton?: string;
  /** Text to show when no results are returned. Has a default value.*/
  noResultsText?: string;
  /** Text to show above the qualifiers section. Has a default value.*/
  optionalLabelsTitle?: string;
  /** Determines if optional qualifiers will be used or not. Defaults to "enabled".*/
  qualifiersStatus?: 'enabled' | 'disabled';
  /** If set to true, items will display the discount percentage badge */
  showBadge?: boolean;
  /** If enabled, the add to cart button will be shown on the product item */
  enableAddToCartButton?: boolean;
  /** Callback that will receive the product as a parameter */
  onAddToCart?: (item: Product) => void;
  /** Text to display in the add to cart button */
  addToCartButtonText?: string;
  /** This function is passed the product data and returns a boolean.
   * When enableAddToCartButton is set to "true", if the function returns "true", the cart button will be displayed.
   */
  validateShowAddToCartButton?: (product: Product) => boolean;
  /** Optional parameter for setting the default list item layout */
  orientation?: Orientation;
  /** Collapse/Expand facet's section container */
  initialCollapsedSections?: boolean;
  /** Optional facet list limit */
  facetListLimit?: number;
  /**
   * List of extra options to sort the list that will be concatenated to the default ones
   *
   * If you are using our API, please confirm what fields are supported for sorting.
   */
  extraSortOptions?: SortOption[];
  /** Optional initial sort option */
  initialSortOption?: string;
  /** Enable scroll to top automatically after update. Default value: true */
  autoScrollToTop?: boolean;
  /** Hook to add extra custom fields to product card center or right section */
  addNewCenterProductField?: (data: Product) => JSX.Element;
  addNewRightProductField?: (data: Product) => JSX.Element;
};
const ResultsPage = ({
  /* Description */
  groupId,
  initialFacets = {},
  fitmentSelectorSearchButton = 'Search',
  fitmentSelectorClearButton = 'Clear',
  noResultsText,
  fitmentSelectorTitle,
  optionalLabelsTitle,
  qualifiersStatus = 'enabled',
  showBadge = true,
  onAddToCart,
  enableAddToCartButton = false,
  addToCartButtonText,
  validateShowAddToCartButton,
  orientation = 'horizontal',
  facetListLimit,
  extraSortOptions,
  initialSortOption,
  autoScrollToTop = true,
  addNewCenterProductField,
  addNewRightProductField,
  initialCollapsedSections,
}: Props) => {
  const queryString = new URLSearchParams(window.location.search);
  const [searchParams, setSearchParams] = useState<any>(
    getSearchParams(new URLSearchParams(window.location.search))
  );
  const [facets, setFacets] = useState<any>({
    ...initialFacets,
    ...getFacetKeysFromUrl(queryString).reduce((acc, facet) => {
      const values = queryString.getAll(facet);
      if (values.length) {
        return {
          ...acc,
          [facet.replace(FACET_PREFIX, '')]: queryString.getAll(facet),
        };
      }
      return acc;
    }, {}),
  });
  const [facetOptions, setFacetOptions] = useState<any>({});
  const [search, setSearch] = useState<string>(getQueryParam() || '');
  const [selectedFitmentQuery, setSelectedFitmentQuery] = React.useState(
    getInitialFitment(queryString)
  );
  const [qualifiers, setQualifiers] = useState({});
  const [entity] = React.useState(localStorage.getItem('entity') || '');
  const [productsQuery, setProductsQuery] = React.useState(
    getProductQuery(facets, selectedFitmentQuery, groupId, queryString)
  );
  const [clearFacetsOnSave, setClearFacetsOnSave] = useState(false);
  const [selectedFitment, setSelectedFitment] = useState(null);
  const clearSelectedFitment = () => {
    setSelectedFitment(null);
    localStorage.removeItem(FITMENT_SELECTOR_STORAGE_KEY);
    setSelectedFitmentQuery(getInitialFitment(queryString));
  };
  const handleClearInput = () => {
    setSearch('');
  };

  // Updates fitment when changed from another component
  useEffect(() => {
    const updateFitment = (e: CustomEvent) => {
      const newFitment = e.detail.fitment;
      setSelectedFitmentQuery(Object.values(newFitment).join('|'));
      clearVqParams();
    };
    window.addEventListener('PL_FITMENT_CHANGED', updateFitment);

    return () => {
      window.removeEventListener('PL_FITMENT_CHANGED', updateFitment);
    };
  }, []);

  useEffect(() => {
    // We don't want to update the product query object on first render becasue was already
    // set on the useState function, so we wait for a change on selectedFacets or selectedFitmentQuery
    const query = getProductQuery(
      facets,
      selectedFitmentQuery,
      groupId,
      queryString
    );
    setProductsQuery(query);
  }, [facets, selectedFitmentQuery, qualifiers, entity]);

  React.useEffect(() => {
    const urlOrientation = new URLSearchParams(window.location.search).get(
      'orientation'
    );
    setSearchParams({
      ...searchParams,
      orientation: (urlOrientation as Orientation) || orientation,
    });
  }, []);

  return (
    <div className={s['results-page-container']}>
      <div className={s['top-section']}>
        <BreadCrumb
          filters={
            search
              ? [
                  {
                    color: '#f8f8f8',
                    id: 1,
                    label: `Keyword: ${search}`,
                  },
                ]
              : []
          }
          onRemoveFilter={() => {
            setSearch('');
            clearSearchParam();
            setSearchParams(
              getSearchParams(new URLSearchParams(window.location.search))
            );
          }}
          paths={{
            list: [
              {
                label: 'Home',
                url: '/',
              },
              {
                label: 'Search',
                url: window.location.pathname,
              },
            ],
            separator: '/',
          }}
        />
      </div>
      <div className={s['bottom-section']}>
        <div className={s['left-column']}>
          <div
            className={`${s['fitment-selector-container']} pl-fitment-selector-section`}
          >
            {fitmentSelectorTitle && (
              <div className={`${s['title']} pl-fitment-selector-title`}>
                {fitmentSelectorTitle}
              </div>
            )}
            <ParslogicFitmentSelectorWrapper
              groupId={groupId}
              orientation="vertical"
              styled
              className={s['fitment-selector']}
              onSubmit={(ftm, opts) => {
                if (opts && !isEmpty(opts)) {
                  const params = new URLSearchParams(window.location.search);
                  // set selected vqs in url
                  for (const vqName in opts) {
                    params.set(`vq[${vqName}]`, opts[vqName]);
                  }
                  // clean vqs that were deselected
                  params.forEach((value, key) => {
                    if (key.includes('vq[')) {
                      const prop = key.replace('vq[', '').replace(']', '');
                      if (!opts[prop]) {
                        params.delete(key);
                      }
                    }
                  });
                  window.history.pushState(
                    {},
                    '',
                    window.location.pathname + `?${params.toString()}`
                  );
                  setQualifiers(opts);
                }
                const fitmentIsNotChanged = isEqual(
                  ftm || {},
                  selectedFitmentQuery || {}
                );
                if (clearFacetsOnSave && !fitmentIsNotChanged) {
                  setFacets({});
                  getFacetKeysFromUrl(queryString).forEach((item) => {
                    updateUrl<string>(`${FACET_PREFIX}${item}`, []);
                  });
                }
                return ftm;
              }}
              onClearCb={() => {
                // Clear also search.
                handleClearInput();
                clearSearchParam();
                clearSelectedFitment();
                const optValues = parseVehicleQualifierQuery(
                  new URLSearchParams(window.location.search)
                );
                Object.keys(optValues || {}).forEach((vqKeys) => {
                  updateUrl<string>(vqKeys, []);
                });
                if (clearFacetsOnSave) {
                  setFacets({});
                  getFacetKeysFromUrl(queryString).forEach((item) =>
                    updateUrl<string>(`${FACET_PREFIX}${item}`, [])
                  );
                }
              }}
              qualifiersStatus={qualifiersStatus}
              searchButtonText={fitmentSelectorSearchButton}
              clearButtonText={fitmentSelectorClearButton}
              optionalLabelsTitle={optionalLabelsTitle}
              loadingQualifiersText=""
              selectedVinFitment={selectedFitment}
            />
          </div>
          <FacetFilter
            enableCollapse
            styled
            onChange={(e) => {
              if (!loadash.isEqual(e.selectedValues, facets)) {
                updateFacetQueryParams(e.selectedValues);
                setFacets(e.selectedValues);
              }
            }}
            listLimit={facetListLimit}
            selectedValues={facets}
            data={facetOptions}
            initialCollapsedSections={initialCollapsedSections}
          />
        </div>
        <div className={s['results-container']}>
          {
            <PartslogicProductListWrapper
              enableAddToCartButton={enableAddToCartButton}
              onAddToCart={onAddToCart}
              addToCartButtonText={addToCartButtonText}
              validateShowAddToCartButton={validateShowAddToCartButton}
              styled
              showPagination
              showControls
              showLoadingIndicator
              showBadge={showBadge}
              renderNoResults={
                noResultsText
                  ? () => (
                      <h4 style={{ textAlign: 'center' }}>{noResultsText}</h4>
                    )
                  : undefined
              }
              onDataReceived={(e) => {
                // When results come in, we update the facets available, but only if they changed
                const {
                  facets,
                  clearFacetsOnFitmentSave,
                  numberOfFitmentsTosave,
                } = e;
                if (
                  localStorage.getItem(FITMENT_SELECTOR_STORAGE_KEY) !==
                    e.fitment &&
                  search
                ) {
                  localStorage.setItem(
                    FITMENT_SELECTOR_STORAGE_KEY,
                    JSON.stringify([`${e.fitment}`])
                  );
                  setSelectedFitment([`${e.fitment}`]);
                  clearVqParams();
                }
                const parsedFacets = {};
                facets.forEach((item) => {
                  const facet = {
                    title: item.name,
                    values: item.values,
                  };
                  parsedFacets[item.name] = facet;
                });
                if (!loadash.isEqual(facetOptions, parsedFacets)) {
                  setFacetOptions(parsedFacets);
                }
                setClearFacetsOnSave(clearFacetsOnFitmentSave);
                setNumberOfFitmentsTosave(numberOfFitmentsTosave);
              }}
              onPageChanged={(page) => {
                setSearchParams({ ...searchParams, page });
                autoScrollToTop &&
                  setTimeout(() => {
                    window.scrollTo({ behavior: 'smooth', top: 0 });
                  }, 1);
                if (page !== 1) {
                  updateUrl('page', page);
                } else updateUrl('page', null);
              }}
              itemsPerPage={searchParams.limit}
              onItemsPerPageChange={(value) => {
                setSearchParams({ ...searchParams, limit: value });
                updateUrl('limit', value);
              }}
              currentPage={searchParams.page}
              search={searchParams.q || ''}
              selectedSort={searchParams.sort || initialSortOption}
              onSortChange={(value) => {
                setSearchParams({ ...searchParams, sort: value });
                updateUrl('sort', value);
              }}
              onLayoutChange={(value) => {
                setSearchParams({ ...searchParams, orientation: value });
                updateUrl<keyof SearchParams>('orientation', value);
              }}
              query={productsQuery}
              orientation={searchParams.orientation}
              extraSortOptions={extraSortOptions}
              addNewCenterProductField={addNewCenterProductField}
              addNewRightProductField={addNewRightProductField}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default ResultsPage;
